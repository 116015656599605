<template>
  <div class="page-header flex justify-between">
    <div class="logo flex">
      <img
        src="../assets/images/logo.png"
        alt="芝兰管理系统 logo"
      />
      <em>芝兰管理系统 </em>
    </div>
    <div class="nav">
      <ul class="flex">
        <li
          :class="[item.meta.title == currentRoute.meta.title ? 'active' : '']"
          v-for="(item, index) in routesList"
          :key="index"
          @click="goToRoute(index)"
        >
          {{ item.meta.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { toRaw } from "vue";

export default {
  name: "Header",
  setup() {
    let router = toRaw(useRouter());
    const currentRoute = router.currentRoute.value;
    const routesList = router.options.routes.filter((item) => {
      return item.meta !== undefined && item.meta.show === true;
    });

    // 跳转路由
    const goToRoute = (index) => {
      if (routesList[index].meta.title !== currentRoute.meta.title) {
        router.push(routesList[index].path);
      }
    };

    return {
      routesList, // 路由列表
      currentRoute, // 当前路由
      goToRoute,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 100px;
  min-width: 1200px;
  padding: 0 2%;
  .logo {
    align-items: center;
    img {
      width: 60px;
      height: 60px;
    }
    em {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      color: #333;
    }
  }
}
.nav {
  margin-right: 40px;
  ul {
    height: 100%;
    align-items: center;
    li {
      margin: 0 25px;
      cursor: pointer;
    }
    li.active {
      color: #00c6ff;
    }
    li:hover {
      color: #00c6ff;
    }
  }
}
</style>
