<template>
  <div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="banner">
      <Banner></Banner>
    </div>

    <div class="page-body">
      <BreadcrumbNavi></BreadcrumbNavi>
      <div class="wrap">
        <div class="about">
          <div class="about-title">
            <h3>{{ details.name }}</h3>
          </div>
          <div class="goods-extend">
            <h5>商品名称：{{ details.extend.name }}</h5>
            <div class="goods-extend-wrap flex">
              <span>品牌：{{ details.extend.brand }}</span>
              <span>规格：{{ details.extend.size }}</span>
              <span>重量：{{ details.extend.weight }}</span>
              <span>生产厂商: {{ details.extend.producer }}</span>
              <span>批准文号：{{ details.extend.serial }}</span>
              <span>产品类型： {{ details.extend.type }}</span>
            </div>
          </div>
          <div class="about-text">
            <p>
              {{ details.info }}
            </p>
          </div>
          <div class="about-img">
            <img :src="details.coverImg" alt="" srcset="" />
          </div>
          <div class="about-img">
            <img :src="details.manualImg" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";
import BreadcrumbNavi from "@/components/BreadcrumbNavi.vue";
import { drugList } from "@/products/drugList.js";

import { useRoute } from "vue-router";

export default {
  components: {
    Header,
    Banner,
    Footer,
    BreadcrumbNavi,
  },
  setup() {
    const route = useRoute();
    const index = route.query.index || 0;
    const details = drugList[index];

    return {
      index,
      details,
    };
  },
};
</script>
<style lang="scss" scoped>
.about {
  text-align: center;
  padding-top: 40px;
  .about-title h3 {
    font-size: 24px;
    color: #333;
    font-weight: normal;
    margin-bottom: 20px;
  }
  .about-img {
    text-align: center;
    margin-bottom: 20px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .about-text {
    width: 650px;
    margin: 0 auto;
    p {
      font-size: 16px;
      line-height: 1.8;
      margin: 20px 0;
    }
  }
  .goods-extend {
    width: 700px;
    margin: 0 auto;
    text-align: left;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    padding: 14px 30px;
    margin-bottom: 10px;
    vertical-align: top;
    font-weight: 400;
    font-size: 12px;
    color: #818181;
    .goods-extend-wrap {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    h5,
    span {
      font-weight: normal;
      text-align: left;
      vertical-align: top;
      font-weight: 400;
      font-size: 12px;
      color: #818181;
      padding: 2px 0;
    }
    span {
      width: 50%;
    }
  }
}
</style>
